
import { PackageService } from "src/services";
import { Toast } from "vant";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import differentCompare from "./different-compare.vue"


@Component({
    components:
    {
        differentCompare
    }
})
export default class PackageComparing extends Vue
{
    /**
     * 是否显示
     * @private
     * @returns string
     */
     @Prop({type: Boolean, default: false})
    private value: boolean;

    /**
     * 是否显示价格
     * @private
     * @returns string
     */
     @Prop({type: Boolean})
    private price: true;
    
    /**
     * 是否显示划线价
     * @private
     * @returns string
     */
    @Prop({type: Boolean})
    private scribe: true;

    /**
     * 套餐列表
     * @private
     * @returns string
     */
     @Prop({type: Array})
    private medicalList: any;

    /**
     * 是否显示
     * @private
     * @returns boolean
     */
    private isShow: boolean = true;

    /**
     * 是否显示差异对比
     * @private
     * @returns boolean
     */
    private showDifferentPopup: boolean = false;

    /**
     * 全项对比
     * @private
     * @returns boolean
     */
     private wholeComparing: any  = [];


     /**
     * 差异项对比
     * @private
     * @returns boolean
     */
     private differenceComparing: any  = [];


    /**
     * 组件创建钩子
     * @private
     * @returns string
     */
     private created()
    {
        
    }

    /**
     * 是否显示对比弹框 
     * @private
     * @returns boolean
     */
     private get showPopup(): boolean
    {
        return this.value;
    }

    /**
     * 返回弹框状态 
     * @private
     */
    private set showPopup(value: boolean)
    {
        this.$emit("input", value);
    }


    /**
     * 选中id集合
     * @private
     */
    private checkeIds: Array<number | string> = [];


    /**
     * 关闭不同对比
     * @private
     */
    private closeDifferentCompare(bol) {
        this.showDifferentPopup = false
        if(bol) {
            this.closePopup()
        }
    }

    /**
     * 改变当前选中套餐行
     * @private
     */
    private onChangeCur(id: any)
    {
        if (this.checkeIds.includes(id))
        {
            this.checkeIds = this.checkeIds.filter(item => item !== id);
        }
        else
        {
            this.checkeIds.push(id);
        }
    }

    /**
     * 关闭
     * @private
     */
    private onCancel () {
        this.isShow = false;
    }
    
    /**
     * 进行对比
     * @private
     */
    private async onCompare()
    {
        if(this.checkeIds.length < 2) {
            Toast('请至少选择两项套餐进行对比')
            return
        }
        await this.packageComparing()
        this.showDifferentPopup = true
    }

    /**
     * 套餐对比
     * @private
     */
     private async packageComparing() {
       try {
        const {content:result} = await PackageService.instance.packageComparing(this.checkeIds);
        this.wholeComparing = result.data.wholeComparing
        this.differenceComparing = result.data.differenceComparing

       } catch (error) {
          Toast(error)
       }
        
    }


    /**
     * 关闭弹窗
     * @private
     */
    private closePopup() {
        this.$emit("close")
        this.checkeIds = []
    }
    
}
